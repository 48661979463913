@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #333;
  font-style: normal;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 16px;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0 20px;
}

@media only screen and (max-width: 640px) {
  .inner {
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .inner {
    padding: 64px 0 20px;
  }
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
  margin-top: 50px;
  margin-bottom: 100px;
}

@media only screen and (max-width: 834px) {
  #contents {
    margin-bottom: 50px !important;
  }
}

@media only screen and (max-width: 834px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #main {
    float: none !important;
  }
}

#col_main {
  width: 76% !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #col_main {
    float: none !important;
    width: 100% !important;
  }
}

#side {
  width: 18.75000%;
}

#col_side1 {
  width: 22% !important;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

.imgR, .imgL {
  max-width: 100% !important;
  float: none;
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  float: none;
  max-width: 100% !important;
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
}

.hukidashi {
  background: url(../images/common/hukidashi.png) right no-repeat;
  padding: 15px 35px 15px 15px;
  width: 193px;
  height: 84px;
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  .hukidashi {
    display: none;
  }
}

@media only screen and (max-width: 834px) {
  .hukidashi {
    display: none;
  }
}

#hedInfo {
  max-width: 555px;
}

#hedInfo .ttl {
  padding: 2px 5px;
  background-color: #0066FF;
  line-height: 1;
}

@media only screen and (max-width: 834px) {
  #hedInfo .ttl {
    padding: 10px;
  }
}

#hedInfo ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f3f3f3;
  padding: 10px;
}

@media only screen and (max-width: 640px) {
  #hedInfo ul {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #hedInfo ul {
    justify-content: center;
  }
}

#hedInfo ul li:nth-of-type(2) {
  margin-right: 10px;
}

@media only screen and (max-width: 834px) {
  #hedInfo {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    max-width: 100%;
    background-color: #f3f3f3;
  }
}

.hed_right {
  width: 47%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .hed_right {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 10px;
    z-index: 1;
    justify-content: center;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .hed_right {
    width: 60%;
  }
}

.hed_right .cont {
  width: 260px;
}

.hed_right .cont a {
  background: #006600;
  padding: 10px;
  display: block;
  border-radius: 50px;
  color: #fff;
  font-size: 1.2em;
}

.hed_right .cont a i {
  margin-right: 7px;
}

.hed_center {
  width: 20%;
}

.hed_center .time {
  text-align: left;
  line-height: 1.5em;
  font-size: 0.8em;
}

.hed_left {
  width: 25%;
  margin-right: 15px;
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  .hed_left {
    width: 25%;
    text-align: center;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .hed_left {
    width: 25%;
  }
}

@media only screen and (max-width: 640px) {
  .hed_left {
    width: 100%;
    text-align: center;
  }
}

#header {
  width: 100%;
}

#header .hedwrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
}

@media only screen and (max-width: 834px) {
  #header .hedwrap {
    display: block;
    padding: 70px 0 0;
  }
}

#header #siteID .logo {
  display: block;
}

@media only screen and (max-width: 834px) {
  #header #siteID .logo {
    display: none;
  }
}

#header #siteID a {
  opacity: 1;
}

#header #siteID .pcnone {
  display: none;
}

@media only screen and (max-width: 640px) {
  #header #siteID .pcnone {
    padding: 10px 0 !important;
    position: fixed;
    top: 6px;
    z-index: 8;
    left: 10px;
    display: block;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header #siteID .pcnone {
    padding: 10px 0 !important;
    position: fixed;
    top: 6px;
    z-index: 8;
    left: 10px;
    display: block;
  }
}

#header .telwrap {
  margin-right: 20px;
}

#header .tel2 {
  width: 260px;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #89ca32;
  color: #fff;
  border-radius: 50px;
  padding: 10px;
  margin-right: 15px;
  white-space: nowrap;
}

#header .tel2 a {
  color: #fff;
}

#header .tel2 i {
  margin-right: 7px;
}

@media only screen and (max-width: 640px) {
  #header .tel2 span {
    display: none;
  }
}

#header .time {
  font-size: 14px;
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  #header .time {
    display: none;
  }
}

@media only screen and (max-width: 834px) {
  #header .time {
    display: none;
  }
}

.hed_info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .hed_info {
    display: block;
  }
}

.hed_info .hed_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .hed_info .hed_cont {
    padding: 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .hed_info .hed_cont {
    justify-content: flex-end;
  }
}

#nav_global {
  padding: 10px 0;
  background: #ffb42a;
  background-size: cover;
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #nav_global {
    padding: 10px;
  }
}

#nav_global .inner {
  padding: 0;
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #nav_global ul {
    display: block;
  }
}

#nav_global ul li {
  width: 100%;
  border-left: 1px solid #fff;
}

#nav_global ul li:first-of-type {
  border-left: none;
}

@media only screen and (max-width: 834px) {
  #nav_global ul li {
    text-align: left;
    padding-left: 20px;
    border-bottom: 1px solid #aa4a92;
  }
}

#nav_global ul li a {
  color: #fff;
  display: block;
}

#nav_global ul li a:hover {
  opacity: 0.6;
}

#nav_global ul .pcnone {
  display: none;
}

@media only screen and (max-width: 834px) {
  #nav_global ul .pcnone {
    display: block;
  }
}

#mainArea {
  width: 100%;
}

#mainArea img {
  width: 100% !important;
  height: auto;
}

.slick-initialized .slick-slide {
  height: auto;
}

#local-keyvisual {
  position: relative;
  background-position: bottom;
}

@media only screen and (max-width: 640px) {
  #local-keyvisual {
    margin-bottom: 30px;
  }
}

#local-keyvisual h1 {
  letter-spacing: 0.1em;
  color: #fff;
  font-size: 3em;
  font-weight: 700;
  z-index: 1;
  position: relative;
  text-align: center;
  text-shadow: 2px 3px 3px #333;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 2.5em;
    padding-left: 100px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 1.7em;
    padding-left: 30px;
  }
}

#local-keyvisual h1 span {
  display: block;
  padding: 100px 0;
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 span {
    padding: 50px 0;
  }
}

#local-keyvisual h1 span:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.pan1 {
  margin-bottom: 20px;
}

@media only screen and (max-width: 640px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

.mean-container .mean-bar {
  background: #89ca32 !important;
  position: fixed !important;
  z-index: 8 !important;
  min-height: 66px !important;
}

.mean-container .mean-nav {
  background: #89ca32 !important;
  margin-top: 66px !important;
}

.mean-container .mean-nav ul li a {
  border-top: 1px solid #fff !important;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  /*background-color: #e9832f !important;*/
}

.mean-container .mean-nav ul li a {
  padding: 10px 5% !important;
}

.mean-container .mean-nav ul li a i {
  margin-right: 15px;
  font-size: 16px;
}

.mean-container a.meanmenu-reveal span {
  background: #fff !important;
  margin-top: 10px !important;
}

.meanmenu-reveal {
  height: 40px !important;
  width: 40px !important;
}

.meanclose {
  font-size: 30px !important;
  padding: 20px 13px 11px 13px !important;
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  background: #89ca32;
}

@media only screen and (max-width: 834px) {
  footer#global_footer {
    margin-bottom: 67px;
  }
}

footer#global_footer .inner {
  padding: 50px 0 80px;
}

@media only screen and (max-width: 834px) {
  footer#global_footer .inner {
    padding: 30px 0;
  }
}

footer#global_footer a {
  color: #333;
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: underline;
}

footer#global_footer #nav_footer {
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

footer#global_footer #nav_footer ul {
  display: block;
  width: 150px;
}

footer#global_footer #nav_footer li {
  text-align: left;
}

footer#global_footer #nav_footer li a {
  text-decoration: none;
  padding: 5px;
  display: block;
  color: #fff;
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #fff;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

.small_bg {
  background: #006600;
  padding: 20px 0;
}

#copy {
  color: #fff;
}

.pagetop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  background: #fff;
  border-radius: 50%;
  z-index: 2;
}

@media only screen and (max-width: 834px) {
  .pagetop {
    bottom: 75px;
  }
}

#footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #footer {
    display: block;
    text-align: center;
  }
}

.ft_L {
  text-align: left;
  width: 48%;
  color: #fff;
}

@media only screen and (max-width: 834px) {
  .ft_L {
    width: 100%;
    text-align: center;
  }
}

.ft_L ul li:nth-of-type(1) {
  margin-bottom: 30px;
}

.ft_nav {
  width: 48%;
}

/* box */
.txt {
  line-height: 1.7em;
}

.h2_01 h2 {
  font-size: 2.2em;
  color: #006600;
  line-height: 1.2em;
}

@media only screen and (max-width: 834px) {
  .h2_01 h2 {
    font-size: 1.4em;
  }
}

.h2_02 h2 {
  font-size: 1.9em;
  color: #fff;
  background: url(../images/common/midashTOP.png);
  padding: 15px 10px;
  line-height: 1.2em;
  background-position: bottom;
  padding: 10px 0px 38px;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 834px) {
  .h2_02 h2 {
    font-size: 1.7em;
  }
}

.h2_03 h2 {
  font-size: 1.9em;
  color: #000000;
}

@media only screen and (max-width: 834px) {
  .h2_03 h2 {
    font-size: 1.4em;
  }
}

.h2_04 h2 {
  font-size: 1.9em;
  color: #fff;
  background: url(../images/common/midashi_LG.gif);
  padding: 20px 0 30px;
  line-height: 1.2em;
  background-position: bottom;
}

.h2_04 h2 span {
  font-size: 0.8em;
  border-bottom: 5px solid #ffff00;
  padding-bottom: 3px;
}

@media only screen and (max-width: 640px) {
  .h2_04 h2 span {
    font-size: 0.6em;
  }
}

@media only screen and (max-width: 834px) {
  .h2_04 h2 {
    font-size: 1.7em;
  }
}

.h2_05 h2 {
  font-size: 1.9em;
  color: #ffffff;
}

@media only screen and (max-width: 834px) {
  .h2_05 h2 {
    font-size: 1.7em;
  }
}

.h2_06 h2 {
  font-size: 1.9em;
  color: #fff;
  background: url(../images/common/midashi_DG.png);
  padding: 10px;
  line-height: 1.2em;
}

@media only screen and (max-width: 834px) {
  .h2_06 h2 {
    font-size: 1.7em;
  }
}

.h2_07 h2 {
  font-size: 1.9em;
  color: #fff;
  background: url(../images/common/midashi_LG.gif);
  padding: 10px;
  line-height: 1.2em;
}

@media only screen and (max-width: 834px) {
  .h2_07 h2 {
    font-size: 1.7em;
  }
}

.h2_08 h2 span {
  font-size: 1.9em;
  color: #000000;
  background: linear-gradient(transparent 70%, #ffff00 70%);
}

@media only screen and (max-width: 834px) {
  .h2_08 h2 span {
    font-size: 1.7em;
  }
}

.h2_09 h2 {
  font-size: 1.9em;
  color: #fff;
  background: url(../images/common/midashi_LG.gif);
  padding: 10px;
  line-height: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 834px) {
  .h2_09 h2 {
    font-size: 1.4em;
  }
}

.h2_09 h2 img {
  margin-right: 10px;
}

.h2_10 h2 {
  font-size: 1.9em;
  color: #333333;
  line-height: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 834px) {
  .h2_10 h2 {
    font-size: 1.4em;
  }
}

.h2_10 h2 img {
  margin-right: 10px;
}

.h2_11 h2 {
  font-size: 1.9em;
  color: #57a153;
  line-height: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 834px) {
  .h2_11 h2 {
    font-size: 1.4em;
  }
}

.h2_11 h2 img {
  margin-right: 10px;
}

.h3_01 h3 {
  font-size: 1.5em;
}

@media only screen and (max-width: 834px) {
  .h3_01 h3 {
    font-size: 20px;
  }
}

.h3_02 h3 {
  font-size: 1.5em;
  border-bottom: 5px solid #ffff00;
  padding-bottom: 3px;
}

@media only screen and (max-width: 834px) {
  .h3_02 h3 {
    font-size: 20px;
  }
}

.h3_02 span {
  background: linear-gradient(to top, #ffd700, #f18a0c);
  border-radius: 5px;
  padding: 10px;
  color: #ffffff;
  font-size: 1.5em;
  white-space: nowrap;
}

.h3_03 h3 {
  font-size: 1.5em;
  border-bottom: 10px solid #ffff00;
  padding-bottom: 3px;
}

@media only screen and (max-width: 834px) {
  .h3_03 h3 {
    font-size: 20px;
  }
}

.h3_03 span {
  font-style: italic;
  font-size: 0.9em;
}

.h3_04 h3 {
  font-size: 1.25em;
  color: #275049;
  background: #e3f3b2;
  padding: 5px;
}

@media only screen and (max-width: 834px) {
  .h3_04 h3 {
    font-size: 20px;
  }
}

.h3_04 span {
  font-style: italic;
  font-size: 0.9em;
}

.h3_02 > div {
  display: flex;
  align-items: center;
}

.h3_05 h3 {
  font-size: 1.5em;
}

@media only screen and (max-width: 834px) {
  .h3_05 h3 {
    font-size: 20px;
  }
}

.h3_05 span {
  font-style: italic;
  font-size: 0.9em;
}

.midashi h2 {
  text-align: center;
  font-size: 1.25em;
}

.midashi h3 {
  font-size: 1.2em;
  text-align: center;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .side_imgwrap {
    display: flex;
    justify-content: space-around;
  }
}

.training {
  position: relative;
  color: #f24c0c;
  font-size: 18px;
}

.training .movietxt {
  position: absolute;
  top: 20px;
  left: 30px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.7);
}

.training .movietxt i {
  margin-right: 15px;
}

@media only screen and (max-width: 834px) {
  #left_11 {
    margin: 15px 0 0 0 !important;
  }
}

.stretch {
  position: relative;
  color: #f24c0c;
  font-size: 18px;
}

.stretch .movietxt {
  position: absolute;
  top: 20px;
  left: 30px;
  background: rgba(255, 255, 255, 0.7);
  padding: 30px;
}

.stretch .movietxt i {
  margin-right: 15px;
}

.side_tel {
  text-align: center;
  color: #f24c0c;
  font-size: 22px;
  font-weight: bold;
  margin-top: 10px;
}

.side_tel i {
  margin-right: 7px;
}

.side_tel a {
  color: #f24c0c;
}

@media only screen and (max-width: 640px) {
  .side_img img {
    width: 100%;
  }
}

.side_cont {
  text-align: center;
  margin-top: 20px;
}

.side_cont a {
  color: #fff;
  display: block;
  background: #ff7a38;
  border-radius: 50px;
  padding: 10px;
  font-size: 13px;
}

.side_cont a i {
  margin-left: 20px;
}

.category:nth-of-type(2) a {
  margin-top: 15px;
}

.category a {
  color: #333333;
  position: relative;
  padding-left: 1.2em;
  /*アイコン分のスペース*/
  line-height: 1.4;
  display: block;
  padding: 10px !important;
  font-size: 13px !important;
  background: #fff;
  margin-top: 10px;
  border-radius: 10px;
}

.category a:after {
  font-family: "Font Awesome 5 pro";
  content: "\f324";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 15px;
  /*アイコンの位置*/
  top: 10px;
  /*アイコンの位置*/
  color: #333333;
  /*アイコン色*/
  font-weight: 400;
}

#col_side1 a.category, #col_side2 a.category {
  background: none !important;
}

#col_side1 {
  width: 21% !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #col_side1 {
    width: 100% !important;
    padding: 10px;
  }
}

.sidebnrwrap {
  margin: 0 10px;
}

.tokuten {
  text-align: center;
  background: url(../images/common/bg_DG.gif);
  padding: 30px;
  font-size: 1.2em;
}

.tokuten a {
  color: #fff;
  display: block;
}

.tokuten span {
  font-size: 1em;
}

.tokuten .kuwashiku {
  font-size: 0.8em;
  border-radius: 50px;
  padding: 5px;
  border: 1px solid #fff;
  margin-top: 15px;
}

.voice {
  background: url(../images/common/bg_LG.gif);
}

.voice a {
  color: #fff;
  text-align: center;
  font-size: 18px;
  padding: 15px;
  display: block;
}

.voice a i {
  margin-left: 20px;
}

.inchou {
  background: url(../images/common/bg_LG.gif);
}

.inchou a {
  color: #fff;
  text-align: center;
  font-size: 18px;
  padding: 15px;
  display: block;
}

.inchou a i {
  margin-left: 20px;
}

.bnrbox {
  padding: 30px 20px;
  background: url(../images/common/bg_LG.gif);
  color: #fff;
}

@media only screen and (max-width: 834px) {
  .bnrbox {
    display: none;
  }
}

.bnrbox2 {
  padding: 30px 20px;
  background: url(../images/common/bg_LG.gif);
  color: #fff;
}

.bnrbox2 .info {
  font-size: 13px;
  color: #333333;
  padding: 10px 5px;
  background: #fff;
  border-radius: 10px;
  line-height: 1.5em;
}

@media only screen and (max-width: 834px) {
  .bnrbox2 .info {
    width: 250px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bnrbox2 {
    display: none;
  }
}

.sec_01 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 834px) {
  .sec_01 {
    display: block;
  }
  .sec_01 .flxL {
    width: 100% !important;
  }
  .sec_01 .flxL .img {
    text-align: center !important;
    margin-bottom: 10px;
  }
  .sec_01 .flxR {
    width: 100% !important;
  }
}

.sec_01 .flxL {
  width: 48%;
}

.sec_01 .flxR {
  width: 48%;
}

.sec_02 {
  background: url(../images/common/onayami_bg.jpg) no-repeat;
  background-size: cover;
  padding: 42px;
}

@media only screen and (max-width: 640px) {
  .sec_02 {
    background-position: right top;
  }
  .sec_02 .flxL {
    width: 20% !important;
  }
  .sec_02 .flxR {
    width: 80% !important;
    background: rgba(255, 255, 255, 0.5);
    padding: 0 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .sec_02 .flxL {
    width: 11% !important;
  }
  .sec_02 .flxR {
    width: 89% !important;
    background: rgba(255, 255, 255, 0.5);
    padding: 20px;
  }
}

.sec_02 .cont2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_02 .cont2 {
    display: block;
  }
  .sec_02 .cont2 .img {
    width: 100% !important;
  }
  .sec_02 .cont2 .list {
    width: 100% !important;
  }
}

.sec_02 .cont2 .img {
  width: 48%;
}

.sec_02 .cont2 .list {
  width: 48%;
  font-size: 18px;
}

.sec_02 .cont2 .list li {
  padding: 10px 0;
}

.sec_02 .cont2 > div:first-of-type {
  display: none;
}

.sec_02 .cont2 > div:last-of-type {
  display: none;
}

.sec_02 .tel2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_02 .tel2 h3 {
    margin-bottom: 10px;
    color: #333;
  }
  .sec_02 .tel2 a {
    color: #e01010;
    font-size: 30px;
  }
  .sec_02 .tel2 img {
    width: 13%;
  }
}

.sec_02 .tel2 h3 {
  font-size: 18px;
  border-bottom: 2px solid #ffff00;
  margin-bottom: 10px;
}

.sec_02 .tel2 img {
  margin-right: 7px;
}

.sec_02 .tel2 > article > div {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 75px;
  margin-top: 10px;
}

.sec_02 .tel2 > article > div a {
  color: #000;
}

.sec_02 .cont2 .btn table {
  border-collapse: separate;
  border-spacing: 10px;
}

.sec_02 .cont2 .btn td {
  border: none !important;
  padding: 0 !important;
}

.sec_03 {
  border: 2px solid #fff;
  padding: 40px;
}

.sec_03 .txt {
  color: #ffffff;
  font-size: 1.25em;
}

.sec_03 .txt span {
  font-size: 1.6em;
  color: #ffff00;
}

@media only screen and (max-width: 834px) {
  .sec_03 .txt span {
    font-size: 1.1em;
  }
}

.sec_03 .txt2 {
  font-size: 1.7em;
  color: #ffffff;
  background: #ff7a38;
  padding: 20px;
  width: 340px;
  margin: 0 auto;
}

.sec_03wrap {
  background: url(../images/common/kokuban.jpg) no-repeat;
  padding: 10px;
  position: relative;
  background-size: cover;
}

.sec_03wrap .gentei {
  position: absolute;
  top: -43px;
  right: 15px;
}

.sec_04 {
  border: 2px solid #006600;
  background: #e3f3b2;
}

.sec_04 .txt {
  color: #000000;
  font-size: 1.25em;
}

.sec_04 .txt span {
  font-size: 1.6em;
  color: #ffff00;
}

.sec_04 .cont2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 640px) {
  .sec_04 .cont2 {
    display: block;
    margin: 10px;
  }
}

.sec_04 .tel2 {
  width: 280px !important;
  font-size: 1.7em;
  background: #ffb42a;
  color: #ffffff;
  border-radius: 50px;
  padding: 15px 20px;
  margin-right: 30px;
}

.sec_04 .tel2 a {
  color: #fff;
}

@media only screen and (max-width: 640px) {
  .sec_04 .tel2 {
    width: 100% !important;
  }
}

.sec_04 .tel2 i {
  margin-right: 7px;
}

.sec_04 .cont_yoyaku a {
  width: 280px !important;
  font-size: 1.4em;
  background: #ff7a38;
  color: #ffffff;
  border-radius: 50px;
  display: block;
  padding: 15px;
}

@media only screen and (max-width: 640px) {
  .sec_04 .cont_yoyaku a {
    width: 100% !important;
    margin-top: 10px;
  }
}

.sec_04 .cont_yoyaku a i {
  margin-right: 7px;
}

.sec_05 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .sec_05 {
    display: block;
  }
  .sec_05 .flxL {
    width: 100% !important;
  }
  .sec_05 .flxL .img {
    text-align: center !important;
    margin-bottom: 10px;
  }
  .sec_05 .flxR {
    width: 100% !important;
  }
}

.sec_05 .flxL {
  width: 48%;
}

.sec_05 .flxR {
  width: 48%;
}

.sec_06 {
  background: #ffffe0;
  padding: 20px;
  border: 5px solid #dcdcdc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .sec_06 {
    padding: 30px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .sec_06 {
    padding: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .sec_06 {
    display: block;
  }
  .sec_06 .flxL {
    width: 100% !important;
  }
  .sec_06 .flxR {
    width: 100% !important;
  }
}

.sec_06 .flxL {
  width: 28%;
}

.sec_06 .flxR {
  width: 68%;
}

.sec_06 .flxR .txt > div {
  color: #003e98;
  font-size: 18px;
  line-height: 1.5em;
}

.sec_06 .flxL > div:first-child {
  display: none;
}

.sec_06 .flxR > div:first-child {
  display: none;
}

.sec_07 {
  background: url(../images/common/sejutu_bg.jpg);
  background-size: cover;
  padding: 40px;
}

.sec_07 .cont2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_07 .cont2 {
    display: block;
  }
}

.sec_07 .cont2 .txt {
  font-size: 1.25em;
}

.sec_07 .cont3 {
  max-width: 600px;
  margin: 0 auto;
}

.sec_07 .flxL > div:first-child {
  display: none;
}

.sec_07 .flxR > div:last-child {
  display: none;
}

.sec_08 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 834px) {
  .sec_08 {
    display: block;
  }
  .sec_08 .flxL {
    width: 100% !important;
  }
  .sec_08 .flxL .img {
    text-align: center !important;
  }
  .sec_08 .flxR {
    width: 100% !important;
  }
}

.sec_08 .flxL {
  width: 48%;
}

.sec_08 .flxR {
  width: 48%;
}

.sec_09 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .sec_09 {
    display: block;
  }
  .sec_09 .flxL {
    width: 100% !important;
  }
  .sec_09 .flxL .img {
    text-align: center !important;
  }
  .sec_09 .flxR {
    width: 100% !important;
  }
}

.sec_09 .flxL {
  width: 48%;
}

.sec_09 .flxR {
  width: 48%;
}

.sec_10 {
  background: url(../images/common/konnakata_bg.jpg);
}

.sec_10 .txt {
  padding: 20px 15px;
}

@media only screen and (max-width: 640px) {
  .sec_10 {
    display: block;
  }
  .sec_10 .img {
    width: 100% !important;
  }
  .sec_10 .txt {
    width: 100% !important;
  }
}

.sec_10 .cont2wrap {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

@media only screen and (max-width: 640px) {
  .sec_10 .cont2wrap {
    display: block;
  }
}

.sec_10 .cont2wrap .cont2 {
  width: 49%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #fff;
}

@media only screen and (max-width: 640px) {
  .sec_10 .cont2wrap .cont2 {
    width: 100% !important;
  }
}

.sec_10 .cont2wrap .cont2 .midashi {
  color: #ffffff;
  font-size: 1.25em;
  padding: 15px;
  background: url(../images/common/bg_DG.gif);
  border-radius: 10px 10px 0 0;
}

.sec_10 .cont2wrap .cont2 .list {
  padding: 30px;
}

.sec_10 .cont2wrap .cont2 .list li {
  padding: 5px 0;
}

.sec_10 .cont2wrap .cont3 {
  width: 49%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #fff;
}

@media only screen and (max-width: 640px) {
  .sec_10 .cont2wrap .cont3 {
    width: 100% !important;
    margin-top: 15px;
  }
}

.sec_10 .cont2wrap .cont3 .midashi {
  color: #ffffff;
  font-size: 1.25em;
  padding: 15px;
  background: url(../images/common/bg_LG.gif);
  border-radius: 10px 10px 0 0;
}

.sec_10 .cont2wrap .cont3 .list {
  padding: 30px;
}

.sec_10 .cont2wrap .cont3 .list li {
  padding: 5px 0;
}

@media only screen and (max-width: 640px) {
  .side_gmap iframe {
    width: 100% !important;
  }
}

.sec_11 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_11 {
    display: block;
  }
  .sec_11 .Dtable_01 {
    width: 100% !important;
  }
  .sec_11 .gmap {
    width: 100% !important;
  }
}

.sec_11 .Dtable_01 {
  width: 48%;
}

.sec_11 .Dtable_01 dt {
  border: none !important;
  display: block !important;
  width: 100% !important;
}

.sec_11 .Dtable_01 dd {
  width: 100% !important;
  border: none !important;
}

.sec_11 .Dtable_01 dl {
  display: block !important;
  border-bottom: 1px dashed #ccc !important;
}

.sec_11 .gmap {
  width: 48%;
}

.sec_11 > div:first-of-type {
  display: none;
}

.sec_11 > div:last-of-type {
  display: none;
}

.sec_12 {
  background: url(../images/common/bg_michi.gif);
  padding: 40px 0;
}

@media only screen and (max-width: 640px) {
  .sec_12 {
    display: block;
  }
  .sec_12 .img {
    width: 100% !important;
  }
  .sec_12 .txt {
    width: 100% !important;
  }
}

.sec_12 > div:first-child {
  display: none;
}

.sec_12 > div:first-child {
  display: none;
}

.sec_13 {
  background: url(../images/common/miti_bg.jpg) no-repeat;
  background-size: cover;
  padding: 40px 0;
}

.sec_13 .cont1 {
  background: rgba(255, 255, 255, 0.5);
  padding: 30px;
  margin: 30px;
}

@media only screen and (max-width: 640px) {
  .sec_13 {
    display: block;
  }
  .sec_13 .img {
    width: 100% !important;
  }
  .sec_13 .txt {
    width: 100% !important;
  }
}

.sec_13 > div:first-child {
  display: none;
}

.sec_13 > div:first-child {
  display: none;
}

.sec_14 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_14 {
    display: block;
  }
  .sec_14 .table_01 {
    width: 100% !important;
  }
  .sec_14 .txt {
    width: 100% !important;
  }
}

.sec_14 .txt {
  width: 42%;
  padding: 0 15px;
}

.sec_14 .txt span {
  font-size: 0.8em;
}

.sec_14 .table_01 {
  width: 58%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 15px !important;
  border: none !important;
}

.sec_14 .table_01 .td_back {
  background: #89ca32;
  color: #ffffff;
}

.sec_14 .table_01 .td_Ltop {
  border-radius: 15px 0 0 0;
}

.sec_14 .table_01 .td_Lbtm {
  border-radius: 0 0 0 15px;
}

.sec_14 .table_01 .td_Rtop {
  border-radius: 0 15px 0 0;
}

.sec_14 .table_01 .td_Rbtm {
  border-radius: 15px 0 0 0;
}

.sec_14 .table_01 td {
  border: none !important;
}

.sec_14 .table_01 tr {
  border-bottom: 1px dotted #ccc !important;
}

.sec_14 .table_01 tr:last-of-type {
  border-bottom: none !important;
}

.sec_14 > div:first-child {
  display: none;
}

.sec_14 > div:first-child {
  display: none;
}

.sec_15 {
  background: url(../images/common/yoyaku_bg.jpg);
  background-size: cover;
  padding: 40px;
}

.sec_15 .txt {
  color: #000000;
}

.sec_15 .txt span {
  font-size: 1.6em;
  color: #ffff00;
}

.sec_15 .txt2 span {
  font-size: 1.12em;
  background: linear-gradient(transparent 70%, #ffff00 70%);
}

.sec_15 .contwrap {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
}

.sec_15 .cont2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 640px) {
  .sec_15 .cont2 {
    display: block;
  }
}

.sec_15 .tel2 {
  width: 280px !important;
  font-size: 1.6em;
  background: #ffb42a;
  color: #ffffff;
  border-radius: 50px;
  padding: 15px 20px;
  margin-right: 30px;
}

.sec_15 .tel2 a {
  color: #fff;
  white-space: nowrap;
}

@media only screen and (max-width: 640px) {
  .sec_15 .tel2 {
    width: 100% !important;
  }
}

.sec_15 .tel2 i {
  margin-right: 7px;
}

.sec_15 .cont_yoyaku a {
  width: 280px !important;
  font-size: 1.4em;
  background: #ff7a38;
  color: #ffffff;
  border-radius: 50px;
  display: block;
  padding: 15px;
}

@media only screen and (max-width: 640px) {
  .sec_15 .cont_yoyaku a {
    width: 100% !important;
    margin-top: 10px;
  }
}

.sec_15 .cont_yoyaku a i {
  margin-right: 7px;
}

.imgbox {
  justify-content: center;
}

.imgbox .box {
  width: 44%;
  text-align: center;
  margin: 15px;
}

@media only screen and (max-width: 640px) {
  .imgbox .box {
    width: 100% !important;
  }
}

.imgbox .box img {
  width: 100% !important;
  height: 250px !important;
}

@media only screen and (max-width: 640px) {
  .imgbox .box img {
    height: auto !important;
  }
}

.imgbox .box h3 {
  font-size: 1.1em;
  font-weight: bold;
  padding: 10px;
  white-space: nowrap;
}

.imgbox .box article > div {
  text-align: left;
}

.con100 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.instagram {
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
}

.instagram .sns_list {
  display: flex !important;
  flex-direction: unset !important;
  border: none !important;
}

@media only screen and (max-width: 640px) {
  .instagram .sns_list {
    display: block !important;
  }
}

.instagram .sns_text {
  display: none;
}

.instagram .sns_photo {
  width: 100% !important;
  padding: 15px !important;
}

.sns_list > div {
  display: flex;
  width: 100%;
  border: none !important;
}

.insta_event .sns_list {
  flex-direction: row;
  flex-wrap: wrap;
  border: none !important;
}

.insta_event .sns_photo {
  width: 30% !important;
}

@media only screen and (max-width: 640px) {
  .insta_event .sns_photo {
    width: 100% !important;
  }
}

.insta_event .sns_text {
  width: 70% !important;
}

@media only screen and (max-width: 640px) {
  .insta_event .sns_text {
    width: 100% !important;
  }
}

.insta_event .sns_date {
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: #89ca32;
  font-style: oblique;
  font-size: 16px;
  font-weight: bold;
  color: #fff !important;
  letter-spacing: 0.1em;
}

.insta_event .caption {
  font-size: 16px;
}

.insta_event div.sns_list > div {
  width: 100%;
  display: flex;
  border-bottom: 1px dashed #ccc;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .insta_event div.sns_list > div {
    flex-direction: unset;
  }
}

.insta_event a {
  color: #FFB42A;
}

.qawrap {
  background: #d0e9ff;
  padding: 10px 30px 30px 30px;
}

.qawrap dt {
  display: block !important;
  width: 100% !important;
  border: none !important;
  color: #003e98;
  font-size: 24px;
  padding: 20px 0 !important;
}

@media only screen and (max-width: 640px) {
  .qawrap dt {
    font-size: 18px;
  }
}

.qawrap dd {
  width: 100% !important;
  border: none !important;
  color: #003e98;
  font-weight: 400;
  font-size: 24px;
  background: #fff !important;
  border-radius: 10px;
  padding: 15px !important;
}

@media only screen and (max-width: 640px) {
  .qawrap dd {
    font-size: 18px;
  }
}

.qawrap dl {
  display: block !important;
  width: 100% !important;
}

@media only screen and (max-width: 640px) {
  .newswrap2 {
    padding: 20px;
  }
}

.newswrap {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
}

.newswrap .news {
  font-size: 18px;
  height: 270px;
  overflow-y: scroll;
}

.newswrap .news dt {
  border: none !important;
  font-weight: 400;
}

@media only screen and (max-width: 640px) {
  .newswrap .news dt {
    display: block !important;
    width: 100% !important;
  }
}

.newswrap .news dd {
  border: none !important;
  font-weight: 400;
}

@media only screen and (max-width: 640px) {
  .newswrap .news dd {
    display: block !important;
    width: 100% !important;
  }
}

.newswrap .news dl {
  border-bottom: 1px dashed #dcdcdc !important;
  padding: 20px !important;
}

@media only screen and (max-width: 640px) {
  .newswrap .news dl {
    display: block !important;
    width: 100% !important;
    padding: 20px 0 !important;
  }
}

.ryoukin {
  max-width: 680px;
  margin: 0 auto;
}

.ryoukin td {
  border: none !important;
}

.ryoukin .td_purasu {
  padding: 0 !important;
  font-size: 30px;
}

.ryoukin .td_txt {
  font-size: 28px;
  padding: 25px 0 !important;
}

@media only screen and (max-width: 640px) {
  .ryoukin .td_txt {
    font-size: 20px;
  }
}

.ryoukinwrap {
  background: #d0e9ff;
  padding: 30px;
}

.btn a {
  color: #fff;
  background: #ffb42a;
  padding: 10px;
  display: block;
  border-radius: 50px;
  width: 200px;
}

.btn a i {
  margin-left: 20px;
}

.btn span {
  position: relative;
  padding-right: 1.2em;
  /*アイコン分のスペース*/
}

.btn span:after {
  font-family: "Font Awesome 5 pro";
  content: "\f0da";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: -50px;
  /*アイコンの位置*/
  top: 0;
  /*アイコンの位置*/
  color: #fff;
  /*アイコン色*/
}

.home_td {
  margin-left: -7.69231% !important;
  display: inline-table;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td {
    margin-left: -7.69231% !important;
  }
}

@media only screen and (max-width: 640px) {
  .home_td {
    margin-left: 0 !important;
  }
}

.home_td .box {
  z-index: 10;
  float: left;
  width: 42.30769% !important;
  margin-left: 7.69231% !important;
  margin-bottom: 30px !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td .box {
    width: 42.30769% !important;
    margin-left: 7.69231% !important;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .home_td .box {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.home_td .box img {
  width: 100% !important;
  border-radius: 15px;
}

.home_td .box .cont_titl {
  display: block;
  margin: 30px 0 10px;
}

.home_td .box .cont_titl h3 {
  font-size: 25px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3 {
    font-size: 20px;
  }
}

.home_td .box .cont_titl h3:first-letter {
  font-size: 35px;
  color: #ef9916;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3:first-letter {
    font-size: 30px;
  }
}

.h2_12 h2 {
  font-size: 1.7em;
  padding: 0.4em 0.5em;
  /*文字の上下 左右の余白*/
  color: #494949;
  /*文字色*/
  background: #f4f4f4;
  /*背景色*/
  border-left: solid 5px #006600;
  /*左線*/
  border-bottom: solid 3px #d7d7d7;
  /*下線*/
}

.h2_13 h2 {
  font-size: 1.7em;
  padding: 0.4em 0.5em;
  /*文字の上下 左右の余白*/
  color: #fff;
  /*文字色*/
  background: #FFB42A;
  /*背景色*/
}

.h3_06 h3 {
  font-size: 1.5em;
  color: #006600;
  background: #F4F4F4;
  padding: 10px;
}

@media only screen and (max-width: 834px) {
  .h3_06 h3 {
    font-size: 20px;
  }
}

.list2 li {
  float: none !important;
  padding: 10px 0;
}

.list3 li {
  float: none !important;
  padding: 10px 0;
}

.movie td {
  text-align: center !important;
  border: none !important;
}

.sec_16 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_16 {
    display: block;
  }
  .sec_16 .txt {
    width: 100% !important;
  }
  .sec_16 .img {
    width: 100% !important;
  }
}

.sec_16 .txt {
  width: 48%;
}

.sec_16 .img {
  width: 48%;
}

.sec_16 > div:first-child {
  display: none;
}

.sec_16 > div:last-child {
  display: none;
}

.sec_17 {
  background: #F4F4F4;
  padding: 30px;
}

.sec_17 .cont2 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_17 .cont2 {
    display: block;
  }
  .sec_17 .cont2 .img {
    width: 100% !important;
  }
  .sec_17 .cont2 .txt {
    width: 100% !important;
    margin-top: 15px;
  }
}

.sec_17 .cont2 .img {
  width: 29%;
}

.sec_17 .cont2 .txt {
  width: 70%;
}

.sec_17 .cont2 img {
  width: 250px !important;
}

.sec_17 .flxL > div:first-child {
  display: none;
}

.sec_17 .flxR > div:last-child {
  display: none;
}

.sec_18 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .sec_18 {
    display: block;
  }
  .sec_18 .flxL {
    width: 100% !important;
  }
  .sec_18 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.sec_18 .flxL {
  width: 48%;
}

.sec_18 .flxL .tel2 {
  width: 320px !important;
  font-size: 1.7em;
  background: #ffb42a;
  color: #ffffff;
  border-radius: 50px;
  padding: 15px 20px;
  margin-right: 30px;
}

.sec_18 .flxL .tel2 a {
  color: #fff;
}

@media only screen and (max-width: 640px) {
  .sec_18 .flxL .tel2 {
    width: 100% !important;
  }
}

.sec_18 .flxL .tel2 i {
  margin-right: 7px;
}

.sec_18 .flxR {
  width: 48%;
}

.sec_18 .flxR .gmap iframe {
  height: 300px !important;
}

#TRANS_ANNOT {
  display: none;
}

.banner iframe {
  max-width: 100% !important;
}

#access {
  margin-top: -120px;
  padding-top: 120px;
}

#tokuten {
  margin-top: -120px;
  padding-top: 120px;
}

#inchou {
  margin-top: -70px;
  padding-top: 70px;
}

.qa dt {
  display: block !important;
  width: 100% !important;
  border: none !important;
}

.qa dt:before {
  content: "Q";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #003e98;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  margin-right: 7px;
}

.qa dd {
  width: 100% !important;
  border: none !important;
}

.qa dd:before {
  content: "A";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #3cb1ff;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  margin-right: 7px;
}

.qa dl {
  border-bottom: 5px dotted #dcdcdc;
  display: block !important;
  padding: 15px 0 !important;
}

.txt05 {
  max-width: 450px;
  font-size: 18px;
}

.txt05 span {
  position: relative;
  padding-left: 1.2em;
  /*アイコン分のスペース*/
  line-height: 1.4;
}

.txt05 span:before {
  font-family: "Font Awesome 5 pro";
  content: "\f14a";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  left: 0;
  /*アイコンの位置*/
  top: 0;
  /*アイコンの位置*/
  color: #0059b3;
  /*アイコン色*/
}

.txt06 {
  color: #e01010;
  font-size: 36px;
  line-height: 1.5em;
  text-shadow: 2px 2px 2px #ccc;
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  .txt06 {
    font-size: 30px;
  }
}

.txt06 br:nth-child(1) {
  display: none;
}

@media only screen and (max-width: 834px) {
  .txt06 br:nth-child(1) {
    display: block;
  }
}

.txt06 br:nth-child(3) {
  display: none;
}

@media only screen and (max-width: 834px) {
  .txt06 br:nth-child(3) {
    display: block;
  }
}

@media only screen and (max-width: 834px) {
  .txt06 {
    font-size: 26px;
  }
}

.table_01 dt {
  border: 1px solid #dcdcdc !important;
}

@media only screen and (max-width: 640px) {
  .table_01 dt {
    display: block !important;
    width: 100% !important;
    text-align: center;
  }
}

.table_01 dd {
  border: 1px solid #dcdcdc !important;
}

@media only screen and (max-width: 640px) {
  .table_01 dd {
    display: block !important;
    width: 100% !important;
    text-align: center;
  }
}

@media only screen and (max-width: 640px) {
  .table_01 dl {
    display: block !important;
  }
}

.cont_tel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cont_tel h3 {
  font-size: 26px;
  border-bottom: 10px solid #ffff00;
  padding-bottom: 5px;
  color: #333;
  margin-bottom: 15px;
}

@media only screen and (max-width: 640px) {
  .cont_tel h3 {
    font-size: 20px;
    border-bottom: 10px solid #ffff00;
    padding-bottom: 5px;
    color: #333;
    margin-bottom: 15px;
  }
}

.cont_tel img {
  margin-right: 7px;
}

.cont_tel article > div {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 75px;
}

@media only screen and (max-width: 640px) {
  .cont_tel article > div {
    color: #000;
    font-size: 30px;
  }
  .cont_tel article > div img {
    width: 13%;
  }
}

.title dt {
  border: none !important;
  font-size: 20px;
  border-bottom: 2px solid #001a81 !important;
}

@media only screen and (max-width: 640px) {
  .title dt {
    display: block !important;
    width: 100% !important;
  }
}

.title dd {
  border: none !important;
  font-size: 20px;
  border-bottom: 2px solid #ccc !important;
}

@media only screen and (max-width: 640px) {
  .title dd {
    width: 100% !important;
  }
}

@media only screen and (max-width: 640px) {
  .title dl {
    display: block !important;
  }
}

.gmap iframe {
  width: 100%;
}

/* title */
.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.bg_100per_wrap {
  position: relative;
  padding: 100px 0 !important;
}

@media only screen and (max-width: 834px) {
  .bg_100per_wrap {
    padding: 50px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont_bg.jpg);
  background-size: cover;
  z-index: -1;
}

.bg_100per_wrap2 {
  position: relative;
  padding: 80px 0 !important;
}

@media only screen and (max-width: 834px) {
  .bg_100per_wrap2 {
    padding: 50px 0 !important;
  }
}

.bg_100per_inner2 {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont2_bg.jpg);
  background-size: cover;
  z-index: -1;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #FFB42A;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.policy {
  padding: 10px 0;
  text-align: center;
}

.policy a {
  color: #FFB42A;
}
